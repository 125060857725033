import React from 'react';

// React classes.
import * as Firebase from 'firebase/app';
import axios from 'axios';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { createRoot } from 'react-dom/client'
import { enableAllPlugins } from 'immer';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';

// Screens.
import Category from './screens/category/index';
import Index from './screens/index';

// Others.
import { AxiosProvider } from './providers/AxiosProvider';
import store from './store/Init';
import ScrollToTop from './hooks/ScrollToTop';

// CSS.
import './assets/css/App.css';

// Initialize Axios.
axios.defaults.baseURL = process.env.REACT_APP_AMAZON_API_URL;

// Initialize Firebase.
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

const firebaseApp = Firebase.initializeApp(firebaseConfig);

// Initialize Immer.
enableAllPlugins();

const WebApp = () => {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route element={ <Category /> } path="/category/:categoryCode" />
                <Route element={ <Index /> } path="*" />
            </Routes>
        </BrowserRouter>
    );
}

const root = document.getElementById("root");

createRoot(root).render(
    <AxiosProvider>
        <Provider store={ store }>
        <Helmet>
            <title>Matteliverse - The fan home of the Mattel Cinematic Universe</title>

            <meta content="Matteliverse - The unofficial fan hangout of the Mattel Cinematic Universe" property="og:title" />
            <meta content="website" property="og:type" />
            <meta content={ document.URL } property="og:url" />
        </Helmet>

        <WebApp />
        </Provider>
    </AxiosProvider>
);