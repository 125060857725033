import React, { createContext, useContext, useEffect, useState } from 'react';

// React classes.
import { connect } from 'react-redux';

export const ThemeContext = createContext({
    isDark: true,
    setScheme: (scheme) => {}
});

const ThemeProvider = (props) => {
    const [isDark, setIsDark] = useState(isDarkColorScheme());

    // Listening to changes of device appearance while in run-time.
    useEffect(() => {
        const mq = window.matchMedia("(prefers-color-scheme: dark)");

        const removeMediaQueryListener = mq.addEventListener("change", (e) => {
            setIsDark(e.matches);
        });

        return removeMediaQueryListener;
    }, []);

    const defaultTheme = {
        isDark: isDark,
        setScheme: (scheme) => setIsDark(scheme == "dark")
    };

    return (
        <ThemeContext.Provider value={ defaultTheme }>
            { props.children }
        </ThemeContext.Provider>
    );

    // Methods.
    function isDarkColorScheme() {
        return window.matchMedia("(prefers-color-scheme: dark)").matches;
    }
};

// Custom hook to get the theme object returns { isDark, setScheme }.
export const useTheme = () => useContext(ThemeContext);

// Store connection.
const mapStateToProps = (state) => {
    const me = state.auth.me;

    return { me };
};

export default connect(mapStateToProps, null)(ThemeProvider);