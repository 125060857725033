import React, { useEffect, useState } from 'react';

// React/Expo classes.
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

// Components.
import BaseIcon from '../../components/base/BaseIcon';
import BaseProduct from '../../components/base/BaseProduct';

// Others.
import categoryList from '../../static/data/category.json';

import { getCurrencyDeets } from '../../functions/deets';
import { init, setCategory, setProducts } from '../../store/CategoryAction';
import { getBrowserLocale } from '../../functions/utils';
import { useTheme } from '../../providers/ThemeProvider';

// CSS.
import style from './index.module.css';

const Screen = (props) => {
    const { categoryCode } = useParams();
    const { isDark } = useTheme();

    const routerLocation = useLocation();
    const routerNavigate = useNavigate();

    const [currency, setCurrency] = useState({});
    const [documentTitle, setDocumentTitle] = useState();
    const [locale, setLocale] = useState({});

    // Hooks.
    useEffect(() => {
        if (categoryCode) {
            props.init(categoryCode, false);

            const browserLocale = getBrowserLocale();

            // const userLocale = browserLocale ? browserLocale : "en-US";
            const userLocale = "en-US";

            const category = categoryList.find((c) => c.category_code == categoryCode);

            if (category) {
                // Get currency details.
                setCurrency(getCurrencyDeets(category.iso_currency_code));

                if (!props.category.category_code) {
                    // Update the Store.
                    props.setCategory(category);
                }

                const loc = category.locales.find((l) => l.locale == userLocale);

                // Since we're always using en-US, we're good here.
                setDocumentTitle(`Matteliverse - ${ loc.category }`)
                setLocale(loc);
            }
            else {
                // You shouldn't be here.
                routerNavigate("/");
            }
        }
        else {
            // Can't load nothing.
            routerNavigate("/");
        }
    }, [categoryCode]);

    if (locale.locale) return (
        <React.Fragment>
            <Helmet>
                <title>{ documentTitle }</title>

                <meta content={ documentTitle } property="og:title" />
            </Helmet>

            <div className={ [style.container, "border-b border-gray-200 pb-5 pt-8 lg:pt-16"].join(" ") }>
                <div className="flex flex-row items-center">
                    <div className="flex-shrink-0">
                        <Link to="/">
                            <BaseIcon alt="Arrow left" name="leftArrow" size={ 20 } style={ style.icon } />
                        </Link>
                    </div>
                    <div className="flex-grow flex-shrink ml-3">

                    </div>
                    <div className="flex-shrink-0 ml-3">
                        <img alt={ locale.category } className="aspect-auto h-10" src={ isDark ? locale.logo_dark : locale.logo_light } />
                    </div>
                </div>
            </div>

            <div className={ [style.container, "py-8 lg:py-16"].join(" ") }>
                <div className="flex flex-col items-center">
                    <div className="gap-x-10 gap-y-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                        {
                            locale.products.map((product, index) =>
                                <BaseProduct currency={ currency } key={ product.asin } product={ product } />
                            )
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
    else return null;

    // Methods.
}

// Store connection.
const mapStateToProps = (state) => {
    const category = state.category.category;
    const products = state.category.products;

    return { category, products };
};

export default connect(mapStateToProps, { init, setCategory, setProducts })(Screen);