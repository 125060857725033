export const addProduct = (product) => (
    {
        type: "ADD_CATEGORY_PRODUCT",
        payload: product
    }
);

export const init = (categoryCode, isRefresh) => {
    return async (dispatch, getState) => {
        const category = getState().category.category;

        // Check to see if we already have this category in the Store. If we do, don't bother fetching it.
        if (isRefresh || !category.category_code || category.category_code != categoryCode) {
            // Let's clean house.
            dispatch(reset());
        }
    }
}

export const reset = () => (
    {
        // Returns the initial state of the Store.
        type: "RESET_CATEGORY_STORE"
    }
);

export const setCategory = (category) => (
    {
        type: "SET_CATEGORY_CATEGORY",
        payload: category
    }
);

export const setProducts = (products) => (
    {
        type: "SET_CATEGORY_PRODUCTS",
        payload: products
    }
);