import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

// React classes.
import axios from 'axios';

// Others.
import { useDebouncedCallback } from 'use-debounce';

export const AxiosContext = createContext();

export const AxiosProvider = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    const activeRequestCount = useRef(0);

    // Hooks.
    const debouncedStop = useDebouncedCallback(() => { if (activeRequestCount.current === 0) setIsLoading(false) }, 250, { leading: false, trailing: true });

    useEffect(() => {
        // Interceptors.
        axios.interceptors.request.use((config) => {
            setLoading(true);

            return config;
        }, (error) => { 
            setLoading(false);

            return Promise.reject(error);
        });

        axios.interceptors.response.use((response) => {
            setLoading(false);

            return response;
        }, (error) => {
            setLoading(false);

            return Promise.reject(error);
        });
    }, []);

    const defaultValue = {
        baseURL: process.env.REACT_APP_API_URL,
        isLoading: isLoading
    };

    return (
        <AxiosContext.Provider value={ defaultValue }>
            { props.children }
        </AxiosContext.Provider>
    );

    // Methods.
    function setLoading(load) {
        if (load) {
            activeRequestCount.current = activeRequestCount.current + 1;

            if (!isLoading) setIsLoading(true);
        }
        else if (activeRequestCount.current > 0 && !load) {
            activeRequestCount.current = activeRequestCount.current - 1;

            if (activeRequestCount.current === 0) debouncedStop();
        }
    }
};

// Custom hook to get the context object.
export const useAxios = () => useContext(AxiosContext);