import React, { useEffect, useState } from 'react';

// React/Expo classes.
import * as Analytics from 'firebase/analytics';
import { getApp } from 'firebase/app';
import { useNavigate } from 'react-router-dom';

// CSS.
import style from './index.module.css';

const Screen = (props) => {
    const firebaseApp = getApp();
    const firebaseAnalytics = Analytics.getAnalytics(firebaseApp);

    const routerNavigate = useNavigate();

    const [year, setYear] = useState();
    
    // Hooks.
    useEffect(() => {
        const date = new Date();
        
        setYear(date.getFullYear());
    }, []);

    return (
        <React.Fragment>
            <div className={ style.container }>
                <div className="h4 md:m3 lg:h2 pt-8 lg:pt-16 text-center">Building <span className="italic">the</span> unofficial fan hangout for the <span className="text-mattel">Mattel</span> Cinematic Universe.</div>

                <div className="mt-2 text-center text-regular">Matteliverse is not affiliated with Mattel, Inc. These Barbies are just huge fans.</div>

                <div className="mt-6 text-center text-large-regular">Say hi? <a href="mailto:hi@matteliverse.com">hi@matteliverse.com</a></div>
            </div>

            <div className="bg-gray-100 mt-20">
                <div className={ [style.container, "py-10"].join(" ") }>
                    <div className="text-x-large-semibold lg:text-xx-large-semibold">Browse some cool gifts while you're here</div>

                    <div className={ [style.categories, "gap-x-10 gap-y-5 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4"].join(" ") }>
                        <div className="flex flex-col items-center">
                            <div className={ style.category } onClick={ () => selectCategory("american_girl") } style={ { backgroundColor: "#C70A4C" } }>
                                <img alt="American Girl" className={ style.categoryLogo } src="/img/logo/american-girl-0.png" />
                            </div>
                        </div>
                        <div className="flex flex-col items-center">
                            <div className={ style.category } onClick={ () => selectCategory("barbie") } style={ { backgroundColor: "#EC4399" } }>
                                <img alt="Barbie" className={ style.categoryLogo } src="/img/logo/barbie-0.png" />
                            </div>
                        </div>
                        <div className="flex flex-col items-center">
                            <div className={ style.category } onClick={ () => selectCategory("fisher_price") } style={ { backgroundColor: "#6AD1E3" } }>
                                <img alt="Fisher-Price" className={ style.categoryLogo } src="/img/logo/fisher-price-fc.png" />
                            </div>
                        </div>
                        <div className="flex flex-col items-center">
                            <div className={ style.category } onClick={ () => selectCategory("hot_wheels") } style={ { backgroundColor: "#009CDE" } }>
                                <img alt="Hot Wheels" className={ style.categoryLogo } src="/img/logo/hot-wheels-fc.png" />
                            </div>
                        </div>
                        <div className="flex flex-col items-center">
                            <div className={ style.category } onClick={ () => selectCategory("uno") } style={ { backgroundColor: "#ED1C24" } }>
                                <img alt="Uno" className={ style.categoryLogo } src="/img/logo/uno-0.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={ [style.container, "mt-10 pb-8 lg:pb-16"].join(" ") }>
                <div className="text-gray-500 text-regular">Brand names and logos are trademarks or registered trademarks of Mattel, Inc. in the United States and other countries.</div>

                <div className="mt-20 text-center text-regular">&copy;2022-{ year } Matteliverse.com, all rights reserved.</div>
            </div>
        </React.Fragment>
    );

    // Methods.
    function selectCategory(categoryCode) {
        // Very first thing: log it!
        Analytics.logEvent(firebaseAnalytics, "select_category", { category: categoryCode, environment: process.env.REACT_APP_ENVIRONMENT });

        routerNavigate(`/category/${ categoryCode }`);
    }
}

export default Screen;