// Others.
import PropTypes from 'prop-types';

const buttonPropTypes = {
    isDisabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    style: PropTypes.string
};

const flexButtonPropTypes = {
    isDark: PropTypes.bool,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    style: PropTypes.string
};

export const ButtonSmallPrimary = (props) => {
    return (
        <span className={ ["button buttonSmall text-small-semibold", props.isDisabled ? "buttonPrimaryDisabled" : "buttonPrimary", props.style ? props.style : null].join(" ") } onClick={ props.onClick }>
            { props.label }
        </span>
    );
};

ButtonSmallPrimary.propTypes = { ...buttonPropTypes };
ButtonSmallPrimary.defaultProps = {
    isDisabled: false,
    style: ""
};

export const ButtonSmallSecondary = (props) => {
    return (
        <span className={ ["button buttonSmall text-small-semibold", props.isDisabled ? "buttonSecondaryDisabled" : "buttonSecondary", props.style ? props.style : null].join(" ") } onClick={ props.onClick }>
            { props.label }
        </span>
    );
};

ButtonSmallSecondary.propTypes = { ...buttonPropTypes };
ButtonSmallSecondary.defaultProps = {
    isDisabled: false,
    style: ""
};

export const ButtonSmallSuperPrimary = (props) => {
    return (
        <span className={ ["button buttonSmall text-small-semibold", props.isDisabled ? "buttonSuperPrimaryDisabled" : "buttonSuperPrimary", props.style ? props.style : null].join(" ") } onClick={ props.onClick }>
            { props.label }
        </span>
    );
};

ButtonSmallSuperPrimary.propTypes = { ...buttonPropTypes };
ButtonSmallSuperPrimary.defaultProps = {
    isDisabled: false,
    style: ""
};

export const ButtonMediumPrimary = (props) => {
    return (
        <span className={ ["button buttonMedium text-semibold", props.isDisabled ? "buttonPrimaryDisabled" : "buttonPrimary", props.style ? props.style : null].join(" ") } onClick={ props.onClick }>
            { props.label }
        </span>
    );
};

ButtonMediumPrimary.propTypes = { ...buttonPropTypes };
ButtonMediumPrimary.defaultProps = {
    isDisabled: false,
    style: ""
};

export const ButtonMediumSecondary = (props) => {
    return (
        <span className={ ["button buttonMedium text-semibold", props.isDisabled ? "buttonSecondaryDisabled" : "buttonSecondary", props.style ? props.style : null].join(" ") } onClick={ props.onClick }>
            { props.label }
        </span>
    );
};

ButtonMediumSecondary.propTypes = { ...buttonPropTypes };
ButtonMediumSecondary.defaultProps = {
    isDisabled: false,
    style: ""
};

export const ButtonMediumSuperPrimary = (props) => {
    return (
        <span className={ ["button buttonMedium text-semibold", props.isDisabled ? "buttonSuperPrimaryDisabled" : "buttonSuperPrimary", props.style ? props.style : null].join(" ") } onClick={ props.onClick }>
            { props.label }
        </span>
    );
};

ButtonMediumSuperPrimary.propTypes = { ...buttonPropTypes };
ButtonMediumSuperPrimary.defaultProps = {
    isDisabled: false,
    style: ""
};

export const ButtonSmallAmazon = (props) => {
    return (
        <div className={ ["buttonSmall flexButton flexButtonAmazon", props.style ? props.style : null].join(" ") } onClick={ props.onClick }>
            <img alt="Amazon" border="0" className="h-5 w-5" src={ props.isDark ? "/img/icon/amazon-fc.png" : "/img/icon/amazon-black.png" } />
            <span className="flexButtonAmazonText text-small-semibold">{ props.label }</span>
        </div>
    );
};

ButtonSmallAmazon.propTypes = { ...flexButtonPropTypes };
ButtonSmallAmazon.defaultProps = {
    isDark: false,
    style: ""
};

export const ButtonSmallApple = (props) => {
    return (
        <div className={ ["buttonSmall flexButton flexButtonApple", props.isDark ? "shadow-dark" : "shadow-light", props.style ? props.style : null].join(" ") } onClick={ props.onClick }>
            <img alt="Apple" border="0" className="h-5 w-5" src="/img/icon/apple-0.png" />
            <span className="flexButtonAppleText text-small-semibold">{ props.label }</span>
        </div>
    );
};

ButtonSmallApple.propTypes = { ...flexButtonPropTypes };
ButtonSmallApple.defaultProps = {
    isDark: false,
    style: ""
};

export const ButtonSmallAppStore = (props) => {
    return (
        <div className={ ["buttonSmall flexButton flexButtonApple", props.isDark ? "shadow-dark" : "shadow-light", props.style ? props.style : null].join(" ") } onClick={ props.onClick }>
            <img alt="Apple" border="0" className="h-5 w-5" src="/img/icon/apple-0.png" />
            <div className="ml-2">
                <div className="flexButtonAppleText text-x-small-light">Download on the</div>
                <div className="flexButtonAppleText text-semibold">{ props.label }</div>
            </div>
        </div>
    );
};

ButtonSmallAppStore.propTypes = { ...flexButtonPropTypes };
ButtonSmallAppStore.defaultProps = {
    isDark: false,
    style: ""
};

export const ButtonSmallFacebook = (props) => {
    return (
        <div className={ ["buttonSmall flexButton flexButtonFacebook", props.isDark ? "shadow-dark" : "shadow-light", props.style ? props.style : null].join(" ") } onClick={ props.onClick }>
            <img alt="Facebook" border="0" className="h-5 w-5" src="/img/icon/facebook-0.png" />
            <span className="flexButtonFacebookText text-small-semibold">{ props.label }</span>
        </div>
    );
};

ButtonSmallFacebook.propTypes = { ...flexButtonPropTypes };
ButtonSmallFacebook.defaultProps = {
    isDark: false,
    style: ""
};

export const ButtonSmallGoogle = (props) => {
    return (
        <div className={ ["buttonSmall flexButton flexButtonGoogle", props.isDark ? "shadow-dark" : "shadow-light", props.style ? props.style : null].join(" ") } onClick={ props.onClick }>
            <img alt="Google" border="0" className="h-5 w-5" src="/img/icon/google-fc.png" />
            <span className="flexButtonGoogleText text-small-semibold">{ props.label }</span>
        </div>
    );
};

ButtonSmallGoogle.propTypes = { ...flexButtonPropTypes };
ButtonSmallGoogle.defaultProps = {
    isDark: false,
    style: ""
};

export const ButtonSmallGooglePlay = (props) => {
    return (
        <div className={ ["buttonSmall flexButton flexButtonGoogle", props.isDark ? "shadow-dark" : "shadow-light", props.style ? props.style : null].join(" ") } onClick={ props.onClick }>
            <img alt="Google Play" border="0" className="h-5 w-5" src="/img/icon/google-play-fc.png" />
            <div className="ml-2">
                <div className="flexButtonGoogleText text-x-small-light">Get it on</div>
                <span className="flexButtonGoogleText text-semibold">{ props.label }</span>
            </div>
        </div>
    );
};

ButtonSmallGooglePlay.propTypes = { ...flexButtonPropTypes };
ButtonSmallGooglePlay.defaultProps = {
    isDark: false,
    style: ""
};

export const ButtonSmallPinterest = (props) => {
    return (
        <div className={ ["buttonSmall flexButton flexButtonPinterest", props.style ? props.style : null].join(" ") } onClick={ props.onClick }>
            <img alt="Pinterest" border="0" className="h-5 w-5" src={ props.isDark ? "/img/icon/pinterest-0.png" : "/img/icon/pinterest-fc.png" } />
            <span className="flexButtonPinterestText text-small-semibold">{ props.label }</span>
        </div>
    );
};

ButtonSmallPinterest.propTypes = { ...flexButtonPropTypes };
ButtonSmallPinterest.defaultProps = {
    isDark: false,
    style: ""
};

export const ButtonSmallSnapchat = (props) => {
    return (
        <div className={ ["buttonSmall flexButton flexButtonSnapchat", props.style ? props.style : null].join(" ") } onClick={ props.onClick }>
            <img alt="Snapchat" border="0" className="h-5 w-5" src={ props.isDark ? "/img/icon/snapchat-black.png" : "/img/icon/snapchat-fc.png" } />
            <span className="flexButtonSnapchatText text-small-semibold">{ props.label }</span>
        </div>
    );
};

ButtonSmallSnapchat.propTypes = { ...flexButtonPropTypes };
ButtonSmallSnapchat.defaultProps = {
    isDark: false,
    style: ""
};

export const ButtonSmallSpotify = (props) => {
    return (
        <div className={ ["buttonSmall flexButton flexButtonSpotify", props.style ? props.style : null].join(" ") } onClick={ props.onClick }>
            <img alt="Spotify" border="0" className="h-5 w-5" src={ props.isDark ? "/img/icon/spotify-fc.png" : "/img/icon/spotify-0.png" } />
            <span className="flexButtonSpotifyText text-small-semibold">{ props.label }</span>
        </div>
    );
};

ButtonSmallSpotify.propTypes = { ...flexButtonPropTypes };
ButtonSmallSpotify.defaultProps = {
    isDark: false,
    style: ""
};