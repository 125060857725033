const currencies = [
    { format_string: "0.0,00", html: "&euro;", icon_name: "euro", iso_currency_code: "EUR",  name: "Euro" },
    { format_string: "0,0.00", html: "&pound;", icon_name: "pound", iso_currency_code: "GBP", name: "Pound Sterling" },
    { format_string: "0,0.00", html: "$", icon_name: "dollar", iso_currency_code: "USD", name: "U.S. Dollar" }
];

export const getCurrencyDeets = (isoCurrencyCode) => {
    const currency = currencies.find((c) => c.iso_currency_code == isoCurrencyCode);

    if (currency) return currency;
    else {
        const fallback = currencies.find((c) => c.iso_currency_code == "USD");

        return fallback;
    }
}