import React, { useEffect, useState } from 'react';

// React classes.
import PropTypes from 'prop-types';

// Others.
import numeral from 'numeral';
import { useTheme } from '../../providers/ThemeProvider';

// CSS.
import style from "./BaseRating.module.css";

const BaseRating = (props) => {
    const { isDark } = useTheme();

    const [dimmedHearts, setDimmedHearts] = useState();
    const [litHearts, setLitHearts] = useState();

    const [frankenDimmed, setFrankenDimmed] = useState();
    const [frankenLit, setFrankenLit] = useState();

    const [cleanRating, setCleanRating] = useState();
    const [cleanRatingCount, setCleanRatingCount] = useState();

    const [size, setSize] = useState(20);

    useEffect(() => {
        const frLit = props.rating >= 0 ? props.rating - Math.floor(props.rating) : 0;
        const frDimmed = props.rating >= 0 ? 1 - frLit : 0;

        setFrankenLit(frLit);
        setFrankenDimmed(frDimmed);

        const lit = props.rating >= 0 ? Math.floor(props.rating) : 0;

        setLitHearts(lit);

        // If we do have a Frankenheart we need to account for it, hence the +1.
        if (frLit > 0) setDimmedHearts(props.rating >= 0 ? props.ratingScale - (lit + 1) : props.ratingScale);
        else setDimmedHearts(props.rating >= 0 ? props.ratingScale - lit : props.ratingScale);

        setCleanRating(props.rating >= 0 ? numeral(props.rating).format("0,0.0") : "Not rated");
        setCleanRatingCount(numeral(props.numRatings).format() + (props.numRatings === 1 ? " rating" : " ratings"));

        setSize(props.large ? 20 : 16);
    }, [props.large, props.numRatings, props.rating, props.ratingScale]);

    return (
        <div className={ [props.style, "flex flex-col items-center"].join(" ") }>
            <div className={ style.ratingContainer }>
                {
                    [...Array(litHearts)].map((_, index) => 
                        <div aria-label="Lit star" className={ [style.heart, style.lit].join(" ") } key={ "lit-star-" + (index + 1) } style={ { height: size, width: size } } />
                    )
                }
                {
                    frankenLit > 0 &&
                    <div aria-label="Lit partial star" className={ [style.heart, style.lit].join(" ") } style={ { height: size, width: size * frankenLit } } />
                }
                {
                    frankenLit > 0 &&
                    <div aria-label="Dimmed partial star" className={ [style.heart, style.dimmed].join(" ") } style={ { backgroundPosition: -size * frankenLit, height: size, width: size * frankenDimmed } } />
                }
                {
                    [...Array(dimmedHearts)].map((_, index) => 
                        <div aria-label="Dimmed star" className={ [style.heart, style.dimmed].join(" ") } key={ "dimmed-star-" + (index + 1) } style={ { height: size, width: size } } />
                    )
                }
            </div>
            <div className={ ["mt-2 text-light-secondaryText dark:text-dark-secondaryText", props.large ? "text-regular" : "text-small-regular"].join(" ") }><span className={ style.rating }>{ cleanRating }</span> &#8226; { cleanRatingCount }</div>
        </div>
    );
}

BaseRating.propTypes = {
    large: PropTypes.bool,
    numRatings: PropTypes.number.isRequired,
    rating: PropTypes.number.isRequired,
    ratingScale: PropTypes.number.isRequired,
    style: PropTypes.string,
    summary: PropTypes.bool
};

BaseRating.defaultProps = {
    large: false,
    style: "",
    summary: false
};

export default BaseRating;