import produce from 'immer';

const INITIAL_STATE = {
    category: {},
    products: []
};

export const categoryReducer = produce((draft, action) => {
    switch (action.type) {
        case "ADD_CATEGORY_PRODUCT":
            // Cheap unshift to plonk it at the top of the list. I guess we'll need to order this at some point.
            draft.products = [action.payload, ...draft.products];

            break;
        case "RESET_CATEGORY_STORE":
            return INITIAL_STATE;
        case "SET_CATEGORY_CATEGORY":
            draft.category = action.payload;

            break;
        case "SET_CATEGORY_PRODUCTS":
            draft.products = action.payload;

            break;
    }
}, INITIAL_STATE);

export default categoryReducer;