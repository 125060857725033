import React, { useEffect, useState } from 'react';

// React classes.
import * as Analytics from 'firebase/analytics';
import { connect } from 'react-redux';
import { getApp } from 'firebase/app';
import PropTypes from 'prop-types';

// Components.
import BaseRating from './BaseRating';
import { ButtonSmallAmazon } from './BaseButton';

// Others.
import numeral from 'numeral';
import { useTheme } from '../../providers/ThemeProvider';

// CSS.
import style from './BaseProduct.module.css';

const BaseProduct = (props) => {
    const { isDark } = useTheme();

    const firebaseApp = getApp();
    const firebaseAnalytics = Analytics.getAnalytics(firebaseApp);

    const [imageUrl, setImageUrl] = useState("");

    // Hooks.
    useEffect(() => {
        setImageUrl(`https://ir-na.amazon-adsystem.com/e/ir?a=${ props.product.asin }&l=li3&t=${ process.env.REACT_APP_AMAZON_STORE_ID }&o=1`);
    }, []);

    if (imageUrl) return (
        <div className={ style.product }>
            <div className={ style.productImageContainer }>
                <img alt="Product" className={ style.productImage } src={ props.product.image_url } />
            </div>

            <div className="mt-5 h9 md:h8 lg:h7 line-clamp-3 text-center text-light-primaryText dark:text-dark-primaryText">{ props.product.name }</div>
            
            {
                props.product.rating?.rating &&
                    <BaseRating
                        numRatings={ props.product.rating.rating_count }
                        rating={ props.product.rating.rating }
                        ratingScale={ props.product.rating.rating_scale }
                        style="mt-2"
                    />
            }

            <div className="flex flex-row items-end mt-4">
                {
                    props.product.list_price &&
                        <div className="flex-row inline-flex items-start text-light-secondaryText dark:text-dark-secondaryText">
                            <div className="line-through text-xx-small-regular">{ props.currency.html }</div>
                            <div className="line-through text-secondaryText text-large-regular">{ numeral(props.product.list_price).format(props.currency.format_string) }</div>
                        </div>
                }
                <div className={ [props.product.list_price ? "ml-2" : null, "flex-row inline-flex items-start text-light-primaryText dark:text-dark-primaryText"].join(" ") }>
                    <div className="text-primaryText text-small-regular">{ props.currency.html }</div>
                    <div className="text-primaryText text-xx-large-regular">{ numeral(props.product.price).format(props.currency.format_string) }</div>
                </div>
                {
                    props.product.is_prime &&
                        <div className="ml-2">
                            <img alt="Prime" className="aspect-auto h-3" src="/img/prime-badge-fc.png" />
                        </div>
                }
            </div>

            <ButtonSmallAmazon isDark={ isDark } label="View on Amazon" onClick={ () => {
                viewProduct();
            } } style="mt-6" />

            
        </div>
    );
    else return null;

    // Methods.
    function viewProduct() {
        // Very first thing: log it!
        Analytics.logEvent(firebaseAnalytics, "view_product", { asin: props.product.asin, category: props.category.category_code, environment: process.env.REACT_APP_ENVIRONMENT });

        window.open(`${ props.product.url }?linkCode=as2&tag=${ process.env.REACT_APP_AMAZON_STORE_ID }`, "_blank");
    }
}

BaseProduct.propTypes = {
    currency: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired
};

// Store connection.
const mapStateToProps = (state) => {
    const category = state.category.category;

    return { category };
};

export default connect(mapStateToProps, null)(BaseProduct);