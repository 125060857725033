// React classes.
import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';

// Others.
import categoryReducer from './CategoryReducer';

const appReducer = combineReducers({
    category: categoryReducer
});

// rootReducer handles just the sign-out action to fully wipe the store.
const rootReducer = (state, action) => {
    if (action.type == "SIGN_OUT") return appReducer(undefined, action);

    return appReducer(state, action);
}
    
export default createStore(rootReducer, applyMiddleware(thunk));